.bg_login{
    background-image: linear-gradient(#fff7fb8c, #230c1661),url("../src/images/BackGround_Login_copy.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}
.logo{
    background-image: linear-gradient(#fff7fb8c, #230c1661),url("../src/images/BackGround_Login_copy.jpg");
    border-radius: 10px;
}
.casinobg{
    background-image: url("./images/livegame.jpg");

}